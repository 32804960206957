import styled  from "styled-components";
import { BoundedContent } from "../../BoundedContent";
import { useScrollHeight } from "../../useScrollHeight";

const Background = styled.div`
background-color:#930FA8;
background-image: linear-gradient(121.55deg, #930FA8, #a8450f);
`

const Container = styled(BoundedContent)`
${props => props.theme.boundedContent}
align-items: center;
display: flex;
flex-direction: column;
gap: ${props => props.theme.spacing.medium};
color: white;
`

const CardRow = styled.div `
width: 100%;
gap: ${props => props.theme.spacing.medium};
display: flex;
flex-wrap: wrap;
justify-content: center;
`

const CardContainer = styled.div `
display: flex;
flex-direction: column;
gap: ${props => props.theme.spacing.small};

width: min(300px, 100%);
align-items: center;
${props => props.theme.breakpoints.tablet} {
width: min(300px, calc(100% / 2 - ${props => props.theme.spacing.medium}));
}

${props => props.theme.breakpoints.desktop} {
width: min(300px, calc(100% / 3 - ${props => props.theme.spacing.medium}));
}
`

const CardTitle = styled.div`
font-weight: 500;
`

const CardDescription = styled.div``

const Heading = styled.div`
font-size: ${props => props.theme.spacing.large};
font-weight: 700;
`

const Description = styled.div`
text-align: center;
font-size: ${props => props.theme.spacing.small};
`

function Card ({ title, description }: { title: string, description: string }) {
  const {containerRef } = useScrollHeight(0.5);

  return (
    <CardContainer ref={ref => containerRef.current =ref!}>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardContainer>
  )
}

export function Contact () {
  return (
    <Background>
      <Container>
        <Heading>Drop us a message</Heading>
        <Description>This website is still under construction. Certain critical information may be missing and links might be broken.<br/>If you have any question, suggestion or inquires, please send us an email at the following:</Description>
        <CardRow>
          <Card title="General Inquiries" description="Contact@Methodox.io"/>
          <Card title="Sales" description="Sales@Methodox.io"/>
          <Card title="Career" description="Career@Methodox.io"/>
        </CardRow>
        <span>Copyright © 2024-2025 Methodox Technologies, Inc.</span>
      </Container>
    </Background>
  );
}

