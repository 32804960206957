import styled  from "styled-components";
import { BoundedContent } from "../../BoundedContent";

const Container = styled(BoundedContent)`
${props => props.theme.boundedContent}
align-items: center;
display: flex;
flex-direction: column;
gap: ${props => props.theme.spacing.medium};
`

const Heading = styled.div`
font-size: ${props => props.theme.spacing.xlarge};
font-weight: 700;
`

const Description = styled.div`
font-size: ${props => props.theme.spacing.small};
`

export function Introduction () {
  return (
    <Container>
      <Heading>Divooka Computing</Heading>
      <Description>Divooka is a cross-platform general-purpose visual computing platform, with AI integrations.</Description>
    </Container>
  );
}

