import styled, { keyframes }  from "styled-components";
import { BoundedContent } from "../../BoundedContent";
import landing from "./landing.png";

const entrance = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`

const DELAY = 0.25;

const Container = styled.div`
  background-color: #4c119b;
  background-image: linear-gradient(121.55deg, ${props => props.theme.palette.l1}, ${props => props.theme.palette.l2});
  height: 100vh;
  overflow: hidden;
`

const Content = styled(BoundedContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: ${props => props.theme.spacing.xlarge};
  height: 100%;
`;

const Heading = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacing.xsmall};
`

const Image = styled.img `
width: min(100%, 700px);
opacity: 0;
animation: ${entrance} 1s ${DELAY*3}s forwards;
`

const Title = styled.h1`
font-size: ${props => props.theme.spacing.xlarge};
opacity: 0;
animation: ${entrance} 1s forwards;
text-align: center;
`;

const Description = styled.span`
opacity: 0;
animation: ${entrance} 1s ${DELAY*1}s forwards;
text-align: center;
`

const Button = styled.button`
margin-top: ${props => props.theme.spacing.large};
border-radius: ${props => props.theme.spacing.large};
color: white;
border: 0px;
cursor: pointer;
font-size: ${props => props.theme.spacing.medium};
opacity: 0;
animation: ${entrance} 1s ${DELAY*2}s forwards;
background: linear-gradient(101.5deg,
${props => props.theme.palette.l3},
${props => props.theme.palette.l4},
${props => props.theme.palette.l5});
                             

font-size: ${props => props.theme.spacing.small};
padding: ${props => `${props.theme.spacing.small} calc(${props.theme.spacing.medium})`};

${props => props.theme.breakpoints.tablet} {
  padding: ${props => `${props.theme.spacing.small} calc(${props.theme.spacing.xlarge})`};
  font-size: ${props => props.theme.spacing.medium};
}
${props => props.theme.breakpoints.medium} {
  padding: ${props => `${props.theme.spacing.small} calc(${props.theme.spacing.xlarge} * 2)`};
}
${props => props.theme.breakpoints.desktop} {
  padding: ${props => `${props.theme.spacing.small} calc(${props.theme.spacing.xlarge} * 4)`};
}

&:hover{
  background: linear-gradient(101.5deg, #770EAA 21.56%, #B72070 74.97%, #E6461E 104.58%);
}
`

export function Landing () {
  return (
    <Container>
      <Content>
        <Heading>
          <Title>Methodox</Title>
          <Description>Innovative ways of thinking. <br/>Make programming and software development fun and efficient.<br/>Use AI effectively and responsibly.</Description>
          <a href="https://www.youtube.com/@DivookaVisualComputing"><Button>Available Soon!</Button></a>
        </Heading>
        <Image alt="" src={landing} />
      </Content>
    </Container>
  );
}
